@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src:
    url('./SourceSans3-Light.ttf.woff2') format('woff2'),
    url('./SourceSans3-Light.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src:
    url('./SourceSans3-LightIt.ttf.woff2') format('woff2'),
    url('./SourceSans3-LightIt.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src:
    url('./SourceSans3-Regular.ttf.woff2') format('woff2'),
    url('./SourceSans3-Regular.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src:
    url('./SourceSans3-It.ttf.woff2') format('woff2'),
    url('./SourceSans3-It.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src:
    url('./SourceSans3-Semibold.ttf.woff2') format('woff2'),
    url('./SourceSans3-Semibold.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src:
    url('./SourceSans3-SemiboldIt.ttf.woff2') format('woff2'),
    url('./SourceSans3-SemiboldIt.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src:
    url('./SourceSans3-Bold.ttf.woff2') format('woff2'),
    url('./SourceSans3-Bold.otf.woff') format('woff');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src:
    url('./SourceSans3-BoldIt.ttf.woff2') format('woff2'),
    url('./SourceSans3-BoldIt.otf.woff') format('woff');
}